import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { PageLoader } from "../components/pageLoader";
import { useAppState } from "../state/appState";
import { QueryFragments } from "../GraphQl/queryFragments"; // eslint-disable-line

// Components
import { PageMeta } from "../components/pageMeta";
import BlockZone from "~blockZone";

const ServiceTemplate = ({ data: { page } }) => {
    const { meta, pageBlocks } = page || {};

    return (
        <>
            <PageMeta {...meta} />

            {pageBlocks && <BlockZone {...pageBlocks} />}
            <PageLoader />
        </>
    );
};

export default ServiceTemplate;

export const pageQuery = graphql`
    query standardServiceQuery($slug: String!) {
        page: sanityService(slug: { current: { eq: $slug } }) {
            meta {
                ...MetaCardFields
            }
            pageBlocks {
                ...BlockZone
            }
        }
    }
`;
